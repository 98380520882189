import React from 'react';
 import image from '../pics/back.jpeg'
 import image1 from '../pics/redgloves.jpg'
 import image2 from '../pics/girlboxingface.jpg'
 import image3 from '../pics/weightloss.webp'
import image4 from '../pics/selfdefence.jpg'
 import image5 from '../pics/handtie.jpg'

 
export default function Facilities() {
  return (
    <div className=' flex flex-col items-center bg-BACK pb-16 pt-8 shadow-slate-200 '>
      <div className="relative mx-auto max-w-5xl text-center pt-7">
        <h2 className="block w-full bg-BACK text-SHADE  mt-10 mb-10   text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl">
        Services We Provide
        </h2>
      </div>


      <section className="text-gray-700 body-font mt-14">
        <div className="container px-5 pt-6 pb-6 mx-auto">
          <div className="flex flex-wrap justify-center -m-3 text-center mx-auto ">
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow  shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden  mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2 " src={image} alt="Sunset in the mountains"  />
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2 ">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Strength training</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                  Unlock Your Full Potential with Strength Training!
Build Lean Muscle, Boost Metabolism, and Feel Powerful! Experience the Ultimate Workout Transformation. Get Fit, Strong, and Confident – Start Today! Join the Fitness Revolution for a Better You!
                  </p>
                </div>
              </div>
            </div>
           
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2" src={image1} alt="Sunset in the mountains"/>
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Boxing Training</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                  Discover the Thrill of Boxing Training!
Unleash Your Inner Champion with Every Punch! Burn Calories, Build Stamina, and Define Your Core. Learn Self-Defense Skills while Getting Fit and Toned. Train like a Fighter, Achieve Your Goals – Begin Your Journey!
                  </p>
                </div>
              </div>
            </div>
            
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2" src={image2} alt="Sunset in the mountains"/>
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Functional Training</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                  Discover Functional Training – Your Key to Real-Life Fitness! 🏋️‍♂️ Enhance Your Daily Activities with Dynamic Workouts! Improve Flexibility, Balance, and Core Strength. Train for a Strong, Resilient Body You Can Rely On! Experience the Power of Functional Fitness – Start Now!
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
      <section className="text-gray-700 body-font pt-12">
        <div className="container px-5 pt-6 pb-6 mx-auto">
          <div className="flex flex-wrap justify-center -m-3 text-center mx-auto ">
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow  shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden  mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2 " src={image3} alt="Sunset in the mountains"  />
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2 ">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Weight Loss</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                  Discover the Secrets to Weight Loss at Our Gym!
We'll Teach You Expert Techniques for Shedding Pounds. Personalized Workouts, Nutrition Plans, and Motivation! Achieve Your Dream Body with Our Dedicated Team.
                  </p>
                </div>
              </div>
            </div>
           
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2" src={image4} alt="Sunset in the mountains"/>
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Self Defence</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                  Discover Self-Defense: Your Path to Safety and Empowerment! 🥋 Benefits: Gain Confidence, Improve Physical Fitness, and Stay Safe. Enhance Your Self-Defense Skills, Build Resilience, and Feel Secure! Join Us to Unleash Your Inner Warrior!
                  </p>
                </div>
              </div>
            </div>
            
            <div className="p-4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 flex-grow shadow-2xl mx-auto transform transition duration-500 hover:scale-110">
              <div className="max-w-sm rounded overflow-hidden shadow-lg mb-8 md:mb-0 md:mr-auto md:ml-auto h-full flex flex-col">
                <img className="w-full border-gray-600 border-t-2 border-r-2 border-l-2" src={image5} alt="Sunset in the mountains"/>
                <div className="px-6 py-4 flex-grow border-gray-600 border-b-2 border-r-2 border-l-2">
                  <div className=" mb-2 text-SHADE block sm:mt-2 text-3xl font-semibold">Stress Relief</div>
                  <p className="text-TEXT text-base mt-3  text-grey1  sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
                  Discover Stress Relief at Its Best! 🌟 Experience Peace, Energy, and Health with Our Gym. Benefits: Reduce Anxiety, Boost Mood, and Improve Sleep. Learn Stress-Management Techniques from Experts.
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
    </div>
  );
}










































// import React from 'react'
// import image from '../pics/back.jpeg'
// import image1 from '../pics/redgloves.jpg'
// import image2 from '../pics/girlboxingface.jpg'
// import image3 from '../pics/weightloss.webp'
// import image4 from '../pics/selfdefence.jpg'
// import image5 from '../pics/handtie.jpg'
// import FrontPagePic from '../Main page/FrontPagePic'

// export default function UpperFront() {
//   const imageStyles = {
//     width: '100%',
//     height: '50%',
//     objectFit: 'cover',
//   };

//   return (
//     <div className='bg-white pt-16'>
     
//       <header>
//         <h2 className='text-center text-2xl sm:text-4xl pt-12 py-2 font-bold text-black pb-4'>
//           FACILITIES AT  Fittera Boxing GYM
//         </h2>
//       </header>
//       <div className='grid sm:grid-cols-1  lg:grid-cols-3 gap-y-16 lg:gap-10 mt-4 w-11/12 mx-auto'>
//         <div className=''>
//           <img src={image} style={imageStyles} alt='Assassin Creed' />
//           <div className='border'>
//             <h2 className='text-center text-3xl p-2'>
//               Strength training
//             </h2>
//             <p className='p-2 text-base'>Unlock Your Full Potential with Strength Training!<br/>
//               Build Lean Muscle, Boost Metabolism, and Feel Powerful!
//               Experience the Ultimate Workout Transformation.
//               Get Fit, Strong, and Confident – Start Today!
//               Join the Fitness Revolution for a Better You!</p>
//           </div>
//         </div>
//         <div className=''>
//           <img src={image1} style={imageStyles} alt='Battle Field' />
//           <div className='border'>
//             <h3 className='text-center text-3xl p-2'>Boxing Training</h3>
//             <p className='p-2 text-base'>Discover the Thrill of Boxing Training!<br/>
//               Unleash Your Inner Champion with Every Punch!
//               Burn Calories, Build Stamina, and Define Your Core.
//               Learn Self-Defense Skills while Getting Fit and Toned.
//               Train like a Fighter, Achieve Your Goals – Begin Your Journey!</p>
//           </div>
//         </div>
//         <div className=''>
//           <img src={image2} style={imageStyles} alt='GTA 5' />
//           <div className='border'>
//             <h3 className='text-center text-3xl p-2'>Functional Training</h3>
//             <p className='p-2 text-base'>Discover Functional Training – Your Key to Real-Life Fitness! 🏋️‍♂️
//               Enhance Your Daily Activities with Dynamic Workouts!
//               Improve Flexibility, Balance, and Core Strength.
//               Train for a Strong, Resilient Body You Can Rely On!
//               Experience the Power of Functional Fitness – Start Now!</p>
//           </div>
//         </div>
//       </div>

//       <div className='grid sm:grid-cols-1 lg:grid-cols-3 gap-y-16 lg:gap-10 pt-20 w-11/12 mx-auto pb-12'>
//         <div className=''>
//           <img src={image3} style={imageStyles} alt='Assassin Creed' />
//           <div className='border'>
//             <h2 className='text-center text-3xl p-2 '>
//               Weight Loss
//             </h2>
//             <p className='text-base p-2'>
//               Discover the Secrets to Weight Loss at Our Gym! <br/>
//               We'll Teach You Expert Techniques for Shedding Pounds.
//               Personalized Workouts, Nutrition Plans, and Motivation!
//               Achieve Your Dream Body with Our Dedicated Team.
//             </p>
//           </div>
//         </div>
//         <div className=''>
//           <img src={image4} style={imageStyles} alt='Battle Field' />
//           <div className='border'>
//             <h3 className='text-center text-3xl p-2'>Self Defence</h3>
//             <p className='text-base p-2'>Discover Self-Defense: Your Path to Safety and Empowerment! 🥋
//               Benefits: Gain Confidence, Improve Physical Fitness, and Stay Safe.
//               Enhance Your Self-Defense Skills, Build Resilience, and Feel Secure!
//               Join Us to Unleash Your Inner Warrior!</p>
//           </div>
//         </div>
//         <div className=''>
//           <img src={image5} style={imageStyles} alt='GTA 5' />
//           <div className='border'>
//             <h3 className='text-center text-3xl p-2'>Stress Relief</h3>
//             <p className='p-2 text-base'>Discover Stress Relief at Its Best! 🌟
//               Experience Peace, Energy, and Health with Our Gym.
//               Benefits: Reduce Anxiety, Boost Mood, and Improve Sleep.
//               Learn Stress-Management Techniques from Experts.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
