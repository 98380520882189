import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Image from '../pics/logo.png';

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="bg-BACK border-b ">
      <div className="mx-auto w-12/12 lg:w-11/12 px-2 sm:px-6 lg:px-8 py-2">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-SHADE"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen ? 'true' : 'false'}
              onClick={toggleMobileMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>
              <svg
                className={`h-6 w-6 ${isMobileMenuOpen ? 'hidden' : 'block'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18M3 6h18M3 18h18" />
              </svg>
              <svg
                className={`h-6 w-6 ${isMobileMenuOpen ? 'block' : 'hidden'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M18 6L6 18M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img className="h-8 lg:h-12 w-auto" src={Image} alt="Your Company"/>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                <Link to="/"  className=" text-TEXT rounded-md px-3 py-2 text-xl font-medium" >Home</Link>
                <Link to="/Facilities" className=" text-TEXT rounded-md px-3 py-2 text-xl font-medium" >Facilities</Link>
                <Link to="/Gallery" className=" text-TEXT rounded-md px-3 py-2 text-xl font-medium" >Gallery</Link>
                <Link to="/AboutUs" className=" text-TEXT rounded-md px-3 py-2 text-xl font-medium" >About Us</Link>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="relative ml-3">
            <div className='bg-BACK text-gold1'>
 
            <Link to="/iframe2" >
    <button type="button" className="focus:outline-none text-SHADE bg-BACK border border-SHADE focus:ring-4 font-medium rounded-lg text-sm px-3 py-2 me-2 font-extrabold">
      Book Trial
    </button>
  </Link>

</div>


            </div>
          </div>
        </div>
      </div>
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu" >
        <div className="space-y-1 px-2 pb-3 pt-2">
          <Link to="/" className="bg-BACK text-TEXT block rounded-md px-3 py-2 text-base font-medium " aria-current="page" onClick={closeMobileMenu}>Home</Link>         
          <Link to="/Facilities" className="bg-BACK text-TEXT block rounded-md px-3 py-2 text-base font-medium" aria-current="page" onClick={closeMobileMenu}>Facilities</Link>     
          <Link to="/Gallery" className="bg-BACK text-TEXT block rounded-md px-3 py-2 text-base font-medium" aria-current="page" onClick={closeMobileMenu}>Gallery</Link>         
          <Link to="/AboutUs" className="bg-BACK text-TEXT block rounded-md px-3 py-2 text-base font-medium" aria-current="page" onClick={closeMobileMenu}>About Us</Link>
                    
        </div>
      </div>
    </nav>
  );
}




































// import React, { useState } from 'react';
// import { Icon } from '@iconify/react';
// import Image from '../pics/logo.png';
// import { Link } from 'react-router-dom';

// export default function Navbar() {
//   const down = {
//     1: { label: 'Home', link: '/' },
//     2: { label: 'Facilities', link: 'Facilities' },
//     3: { label: 'Gallery', link: 'Gallery' },
//     4: { label: 'About Us', link: 'AboutUs' },
//   };

//   const [icon, setIcon] = useState(<Icon icon="octicon:three-bars-16" />);
//   const [showDropdown, setShowDropdown] = useState(false);

//   const handleChange = () => {
//     if (icon.props.icon === 'octicon:three-bars-16') {
//       setIcon(<Icon icon="maki:cross" />);
//       setShowDropdown(true);
//     } else if (icon.props.icon === 'maki:cross') {
//       setIcon(<Icon icon="octicon:three-bars-16" />);
//       setShowDropdown(false);
//     }
//   };

//   return (
//     <div>
//       <nav>
//         <div className="grid grid-cols-3 w-full bg-black text-white p-2 md:p-2 lg:p-2 fixed">
//           <div className=" pt-1  ">
//            <Link to="/"> <img src={Image} className="md:w-8/12 lg:w-6/12 sm:w-5/12" alt="Logo" /></Link>
//           </div>

        
//           <div className="ms-16 md:p-2 p-4 lg:p-4  hidden sm:block md:block w-full ">
//             <ul className="flex ">
//             <Link to="/"> <li className=" p-2 ml-4 lg:text-lg text-white ">
//              Home
//               </li></Link>
//               <Link to="Facilities">  <li className=" p-2 ml-4 lg:text-lg text-white">
//               Facilities
//               </li></Link>
//               <Link to="Gallery">  <li className=" p-2 ml-4 lg:text-lg text-white">
//              Gallery
//               </li></Link>
//               <Link to="AboutUs">   <li className=" p-2 ml-4 lg:text-lg text-white ">
//              AboutUs
//               </li></Link>
//             </ul>
//           </div>

         
         

//           <div className='ml-auto   md:hidden lg:hidden '>
//            <button className="md:text-2xl lg:text-2xl p-1 text-xl mt-2  bg-fuchsia-950 rounded-md" onClick={handleChange}>
//               {icon}
//             </button>
//             {showDropdown && (
//               <ul className="absolute top-12 right-0 bg-gray-950 text-white p-4">
//                <Link to="/"> <li className="py-3 px-3">Home</li></Link>
//                <Link to="Facilities">    <li className="py-3 px-3">Facilities</li></Link>
//                 <Link to="Gallery">    <li className="py-3 px-3">Gallery</li></Link>

//                 <Link to="AboutUs">  <li className="py-3 px-3">About us</li></Link>
//               </ul>
//             )}
//           </div>
//           <div className="items-center gap-2 sm:gap-4 pb-5  md:pt-4 lg:pt-4 pt-1 ml-auto ">
//             <Link to='iframe2'> <button className="md:text-xs  lg:text-xl lg:p-2 p-2 text-xs bg-fuchsia-900 rounded-md hover:bg-fuchsia-700 active:bg-fuchsia-700 focus:outline-none focus:ring focus:bg-fuchsia-700">
//   Book Free Trail
// </button></Link>
         

             
//             </div>


//         </div>
//       </nav>
//     </div>
//   );
// }
