import React from 'react';
import image from '../pics/gallery.jpg';
import image1 from '../pics/gallery1.jpg';
import image2 from '../pics/gallery2.jpg';
import image3 from '../pics/gallery3.jpg';
import image4 from '../pics/gallery4.jpg';
import image5 from '../pics/gallery5.jpg';
import FrontPagePic from '../Main page/FrontPagePic';
import Iframe from '../Main page/Iframe'
export default function Gallery() {
  return (

    <div className="App">
    <div className='bg-BACK'>
   

<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 pt-24 pb-20 px-6 md:px-16 lg:px-28">
<div>



  <img class="object-cover object-center w-full h-56 max-w-full rounded-lg "
   src={image}
    alt="gallery-photo" />
   
</div>
<div>


<img class="object-cover object-center w-full h-56 max-w-full rounded-lg "
src={image1}
alt="gallery-photo" />

</div>
<div>


<img class="object-cover object-center w-full h-56 max-w-full rounded-lg "
src={image2}
alt="gallery-photo" />

</div>
<div>


<img class="object-cover object-center w-full h-56 max-w-full rounded-lg "
src={image3}
alt="gallery-photo" />

</div>
<div>


<img class="object-cover object-center w-full h-56 max-w-full rounded-lg "
src={image4}
alt="gallery-photo" />

</div>
<div>


<img class="object-cover object-center w-full h-56 max-w-full rounded-lg "
src={image5}
alt="gallery-photo" />

</div>

</div>

</div>
<div>
  <Iframe/>
</div>
  </div>
  );
}
