import './App.css';
import Main from './Components/Main page/Main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Main page/Navbar';
import Footer from './Components/Main page/Footer';
import Gallery from './Components/Components2/Gallery';
import Fittera from './Components/Components2/Fittera';
import Iframe2 from './Components/Components2/Iframe2';
import Facilities from './Components/Components2/Facilities';


function App() {
   return (
    <BrowserRouter>
   <Navbar/>
     <Routes>
     <Route path="/Gallery" element={<Gallery/>} />
     <Route path="/" element={<Main/>} />
     <Route path="/AboutUs" element={<Fittera/>} />
     <Route path="/Facilities" element={<Facilities/>} />
     <Route path="/iframe2" element={<Iframe2 />} />
     </Routes>
    <Footer/>
   </BrowserRouter>
  );
}

export default App;
