import React from 'react'
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom"; 
import image from '../pics/logo.png'

export default function Footer() {

  return (

    <footer className=''>
    <div class="bg-BACK py-4 text-TEXT border-t-2 border-gray-600">
      <div class="container px-4 mx-auto">
        <div class="-mx-4 flex flex-wrap justify-between">
          <div class="px-4 my-4 w-full xl:w-3/4">
          <img src={image} className='w-36 '/>
            <p class="text-justify  bg-BACK  text-TEXT text-base sm:text-lg md:mt-5 md:text-xl mt-2">
            Each drop of sweat molds resilience, every punch fortifies strength,
In our boxing arena, determination shapes champions, at any length.
From the grind of the bag to the spar in the ring, we empower,
Transforming sweat into victories, where dreams and realities tower.
            </p>
          </div>

   
          <div class="px-4 my-4 w-full sm:w-auto">
            <div>
              <h2 class="inline-block text-2xl pb-4 mb-4 border-b-4 border-SHADE">Connect With Us</h2>
            </div>
            <ul class="leading-8">
              <li> 
                <span></span> 
                <Link to="https://wa.me/7620383053"  className="block py-2 px-3  rounded  md:p-0  dark:text-TEXT  leading-6 text-TEXT text-base  s sm:text-lg   md:text-lg">
                <button class="  text-TEXT  rounded inline-flex items-center ">
                <span>WhatsApp</span>
                <Icon icon="ic:sharp-whatsapp" className="text-4xl text-efd09e mx-auto text-center p-2 text-green-500" />


</button>
                </Link>
                </li>
        
              {/* <li>            
              <Link to="https://www.linkedin.com/in/sabuwebcraft-craft-a6a82b302/"  className="block py-2 px-3  rounded  md:p-0  dark:text-TEXT  leading-6 text-TEXT text-base  s sm:text-lg   md:text-lg" >
              <button class="  text-TEXT rounded inline-flex items-center ">
                <span>Linkedin</span>
                <Icon icon="devicon:linkedin" className="text-4xl text-efd09e mx-auto text-center p-2" />


</button>
              </Link>

              </li> */}
              <li>            
                  <Link to="https://www.instagram.com/fitt_era?igsh=OTZjaDNidjJja2F2" target="_blank"  className="block py-2 px-3  rounded  md:p-0  dark:text-TEXT  leading-6 text-TEXT text-base  s sm:text-lg   md:text-lg" >
                  <button class="  text-TEXT   rounded inline-flex items-center ">
                <span>Instagram</span>
                <Icon icon="skill-icons:instagram" className="text-4xl text-efd09e mx-auto text-center p-2" />



</button>
                  </Link>
              </li>
            </ul>
          </div>
       
        </div>
      </div>
    </div>
   
  </footer>


















    // <div className='bg-slate-950'>
    //   <div className='text-center mx-auto'>
    //     <h1 className='pt-8 px-20 text-4xl font-bold text-white'>FittEra</h1>
    //     <div className='grid grid-cols-2 mx-auto w-1/12'>
    //       <div>  
    //         <Link to="https://www.instagram.com/fitt_era/" className='pt-2 text-4xl font-bold text-white'>
    //       <Icon icon="skill-icons:instagram" className='mx-auto mt-3'/></Link> 
    //       </div>
    //       <div>
    //          <Link to="https://wa.me/7620383053" target="_blank" className='pt-2 text-4xl font-bold text-white'>
    //             <Icon icon="logos:whatsapp-icon" className='mx-auto mt-3' />
    //           </Link>
    //           </div>
    //     </div>
      
    //     <div className='text-center bg-black mt-4'>
    //         <div><p className=' p-4 text-fuchsia-950'></p></div>  
    //     </div>
    //   </div>
    // </div>
  )
}
