import React from 'react';
 import image from '../pics/punchmain1.jpg'
import { Link } from "react-router-dom";
export default function MiddleBody() {
  return (
    <div className=''>
  <div className="relative mx-auto max-w-5xl text-center pt-16">
        <h2 className="block w-full bg-BACK text-SHADE  mt-10 mb-10   text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl">
        About Owner
        </h2>
      </div>
   
    <section className="bg-BACK pt-4 pb-12">
      <div className="container w-11/12 md:w-9/12 mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
          <div className="max-w-lg">
            <h1 className="text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl text-SHADE">
              <span className="block xl:inline">Amey Nitin Kalambe</span>
            </h1>

            <p className=" text-TEXT mt-3 text-base  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Amey Nitin Kalambe is the founder of FITTERA.
            He is not only a national medalist but also an international professional boxer.
            Amey holds the distinction of being the sole player from Maharashtra to compete in the World Boxing Council India title.
            In 2021, he received the prestigious title of the Best Sportsman of Pune.
            </p>

           
          </div>
          <div className="mt-12 md:mt-0">
            <img
             src={image}
              alt="About Us Image"
              className="object-cover rounded-lg shadow-md h-2/3 w-12/12" // Adjusted height to h-24
            />
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
