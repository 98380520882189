import React from 'react';
import { Icon } from '@iconify/react';

export default function Iframe() {
  return (
<section class="bg-BACK dark:bg-BACK pb-24">
    <div class="container  w-11/12 md:w-9/12 px-6 py-12 mx-auto">
    <div className="relative mx-auto max-w-5xl text-center ">
        <h2 className="block w-full bg-BACK text-SHADE  mt-10 mb-10   text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl">
        Get In Touch
        </h2>
      </div>
        <div class="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-3 pt-14">
            <div class="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-1">
                <div>
                    <span class="inline-block p-3 rounded-full bg-SHADE dark:bg-BACK border border-SHADE">
                    <Icon icon="ic:baseline-email" className='text-3xl'/>
                    </span>

                    <h2 class="mt-4 text-base font-medium text-SHADE">Email</h2>
               
                    <p class="mt-2 text-sm text-TEXT">fitteraboxinggym@gmail.com</p>
                </div>

                <div>
                    <span class="inline-block p-3  rounded-full bg-SHADE dark:bg-BACK border border-SHADE">
                    <Icon icon="mdi:location" className='text-3xl'/>
                    </span>
                    
                    <h2 class="mt-4 text-base font-medium text-SHADE">Location</h2>
                   
                    <p class="mt-2 text-sm text-TEXT">Second Floor, ITI Rd, above Hotel Mainland China, near Sarjaa Hotel,
                    <br></br>  Aundh, Pune, Maharashtra 411007</p>
                </div>

                <div>
                    <span class="inline-block p-3 rounded-full bg-SHADE dark:bg-BACK border border-SHADE">
                    <Icon icon="ic:baseline-phone" className='text-3xl'/>
                    </span>
                    
                    <h2 class="mt-4 text-base font-medium text-SHADE">Phone</h2>
                    <p class="mt-2 text-sm text-TEXT">Mon-Sat from 7am-9pm.</p>
                    <p class="mt-2 text-sm text-TEXT">+91 7620383053</p>
                </div>
            </div>

            <div class="overflow-hidden rounded-lg lg:col-span-2 h-96 lg:h-auto">
                <iframe width="100%" height="100%" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30256.862581004523!2d73.77463366870683!3d18.569177082139763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf0f32ed42c5%3A0x4bd1ba38faa3bdfa!2sFITTERA!5e0!3m2!1sen!2sin!4v1693939190405!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</section>








    // <div className='bg-black'>
    //   <div className='grid sm:grid-cols-1 lg:grid-cols-2 mt-3 text-center md:text-left'>
    //     <div className='md:py-4 md:px-4 sm:px-4 sm:py-2 '>
    //       <h6 className='text-3xl font-bold text-white mt-8'>Gmail</h6>
    //       <p className='mt-1  text-md text-white'>fitteraboxinggym@gmail.com</p>
    //       <h6 className='text-3xl font-bold text-white mt-8'>Phone number</h6>
    //       <p className='mt-1  text-md text-white'>7620383053</p>
    //       <h6 className='text-3xl font-bold text-white mt-8'>Timing</h6>
    //       <p className='mt-1  text-md text-white'>7am-9pm</p>
    //       <h6 className='text-3xl font-bold text-white mt-8'>Location</h6>
    //       <p className='mt-1  text-md text-white'>Second Floor, ITI Rd, above Hotel Mainland China, near Sarjaa Hotel,<br></br>  Aundh, Pune, Maharashtra 411007</p>
    //     </div>
    //     <div className=''>
    //     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30256.862581004523!2d73.77463366870683!3d18.569177082139763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf0f32ed42c5%3A0x4bd1ba38faa3bdfa!2sFITTERA!5e0!3m2!1sen!2sin!4v1693939190405!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    //     </div>
    //   </div>
    // </div>
  );
}
