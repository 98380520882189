import React from 'react';
import image from '../pics/fittera0.png';
import image1 from '../pics/akash2.jpg';
import image2 from '../pics/vai.jpg'
import image3 from '../pics/zzbox.jpg'

export default function Fittera() {
  return (

    <div>

   
    <section className="bg-BACK pt-10 pb-8">
      <div className="container w-11/12  md:w-9/12 mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
          <div className="max-w-lg">
            <h1 className="text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl text-SHADE">
              <span className="block xl:inline">Fittera</span>
            </h1>

            <p className=" text-TEXT mt-3 text-base  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            FITTERA is the boxing gym and sole boxing fitness gym located in close proximity to Aundh and Baner.
            FITTERA's primary objective is to engage individuals of all generations in fitness activities, encompassing a wide range of disciplines such as ground training, yoga, meditation, self-defense, bodyweight training, strength training, and instilling a strong determination to combat various challenges.
            The trainers at FITTERA are not only highly experienced but also distinguished players in the Indian boxing industry.
            </p>

           
          </div>
          <div className="mt-12 md:mt-0">
            <img
             src={image3}
              alt="About Us Image"
              className="object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </section>


    <section className="bg-BACK pb-16">
      <div className="container w-9/12 mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
        <div className="mt-12 md:mt-0">
            <img
             src={image1}
              alt="About Us Image"
              className="object-cover rounded-lg shadow-md max-h-2/3"
            />
          </div>
          <div className="max-w-lg">
            <h1 className="text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl text-SHADE">
              <span className="block xl:inline">Akash Manere</span>
            </h1>

            <p className=" text-TEXT mt-3 text-base  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Akash Manere is a highly experienced trainer who has achieved the title of State Gold Medalist seven times and National Gold Medalist three times. In addition, he was selected to represent his country in the World Championship in 2015.
            </p>

           
          </div>
        
        </div>
      </div>
    </section>
    </div>
















    // <div className='bg-black pt-28'>
    //   <div className='grid sm:grid-cols-1 lg:grid-cols-2 '>
       
    //     <div className='md:py-12 md:px-10 sm:px-4 sm:py-2 text-center'>
    //       <h1 className='text-6xl font-bold text-white mt-20'>Fittera</h1>
    //       <p className='mt-8 text-md text-white'>FITTERA is the boxing gym and sole boxing fitness gym located in close proximity to Aundh and Baner.</p>
    //       <p className='mt-4 text-md text-white'>FITTERA's primary objective is to engage individuals of all generations in fitness activities, encompassing a wide range of disciplines such as ground training, yoga, meditation, self-defense, bodyweight training, strength training, and instilling a strong determination to combat various challenges.</p>
    //       <p className='mt-4 text-md text-white'>The trainers at FITTERA are not only highly experienced but also distinguished players in the Indian boxing industry.</p>
    //     </div>
    //     <div className=''>
    //       <img className='h-auto w-full' src={image} alt=""/>
    //     </div>
    //   </div>
    //   <div className='grid sm:grid-cols-1 lg:grid-cols-2 mt-3 text-center'>
    //   <div className=''>
    //       <img className='h-auto w-full' src={image1} alt=""/>
    //     </div>
    //     <div className='md:py-12 md:px-10 sm:px-4 sm:py-2'>
    //       <h1 className='text-6xl font-bold text-white mt-8 md:mt-80'>Akash Manere</h1>
    //       <p className='mt-8 text-md text-white'>Awash Manere is a highly experienced trainer who has achieved the title of State Gold Medalist seven times and National Gold Medalist three times. In addition, he was selected to represent his country in the World Championship in 2015.</p>
    //     </div>
       
    //   </div>
    // </div>
  );
}
