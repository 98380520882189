import React from 'react'
// import image from '../pics/ameywall.jpg';
import image3 from '../pics/zzbox.jpg'
import { Link } from "react-router-dom";
export default function FrontPagePic() {
  return (
    
    <div>

   
    <section className="bg-BACK pt-10 pb-16">
      <div className="container  w-11/12 md:w-9/12 mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
          <div className="max-w-lg">
            <h1 className="text-4xl tracking-tight font-extrabold  sm:text-4xl md:text-5xl text-SHADE">
              <span className="block xl:inline">Welcome to Fittera Boxing GYM</span>
            </h1>

            <p className=" text-TEXT mt-3 text-base  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Get fit with Fittera, unleash your potential in every jab and hook. Join our tribe , sweat it out and carve your strongest self
            </p>

            <div className="p-2 text-SHADE mt-4 border-SHADE">
              <Link to="https://www.instagram.com/fitt_era?igsh=OTZjaDNidjJja2F2" target="_blank">
                <button className="px-3 py-1.5 flex gap-2 items-center rounded-xl outline outline-2">
                  <div className="text-lg border-gold1">Connect with us</div>
                  {/* <div className="w-10">
                    <Icon icon="skill-icons:instagram" className="text-lg"></Icon>
                  </div> */}
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-12 md:mt-0">
            <img
             src={image3}
              alt="About Us Image"
              className="object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </section>
   
    </div>
  )
}
